/* Main button */
.btn {
    display: inline-flex;
    align-items: center;
    justify-content: start;
    height: 3.8vw;
    position: relative;
    border-radius: 1000px;
    background: white;
    color: black !important;
    font-size: 1.25vw;
    font-family: 'Aeonik';
    text-transform: capitalize;
    border: 1px solid #ffffff;
    width: fit-content;
    min-width: 13vw;
    padding-left: 4vw;
    transition: all 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  .btn .btnText{
    padding-right: 2.5vw;
    padding-left: 0.5vw;
    width: 100%;
    line-height: 1;
    position: relative;
    z-index: 2;
  }
  .btn .btnCircle {
    clip-path: inset(14% calc(100% - 3.3vw) 14% 0.6vw round 1000px);
    position: absolute;
    inset: 0px;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    z-index: 1;
    background-image: linear-gradient(to right, #9E7BDF, #ac26b0, #1e40af);
    background-size: 400%;
    
    color: white;
    transition: all 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  .btn .btnIcon {
    width: 0.8vw;
    height: 0.8vw;
    overflow: hidden;
    margin-left: 1.55vw;
  }
  .btn .btnIcon .btnPath {
    transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transform-origin: center center;
    fill: currentColor;
  }
  .btn .btnIcon .btnPath:first-child {
    transform: translateX(-120%) scale(0);
  }
  .btn:hover {
    color: white !important;
    border: 1px solid white;
    transition: all 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  .btn:hover .btnIcon .btnPath:first-child {
    transform: translateX(0) scale(1);
  }
  .btn:hover .btnIcon .btnPath:last-child {
    transform: translateX(120%) scale(0);
  }
  .btn:hover .btnCircle {
    clip-path: inset(-1px round 4vw);
    animation: AnimationName 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  }
  .buttonAnimate {
    background-size: 400%;
    -webkit-animation: AnimationName 3s ease infinite;
    -moz-animation: AnimationName 3s ease infinite;
    animation: AnimationName 3s ease infinite;
  }
  
  @keyframes AnimationName {
    0%,
    100% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }
  /* Data-Btn-White */
  .btn[data-btn-white]{
    background: white;
    color: var(--body);
    border-color: white;
  }
  .btn[data-btn-white] .btnCircle {
    background-color: var(--primary);
    color: white;
  }
  .btn[data-btn-white]:hover{
    color: white;
  }
  
  /* Data-btn-b-white */
  .btn[data-btn-b-white] {
    border-color: white
  }
  
  /* Data-btn-white */
  .btn[data-btn-2] {
    background: white;
    color: var(--body);
  }
  .btn[data-btn-2] .btnCircle{
    background-color: var(--primary);
    color: white;
  }
  .btn[data-btn-2]:hover{
    color: white;
  }
  
  .linkbutton{
    transition: transform 0.735s cubic-bezier(0.62, 0.05, 0.01, 0.99);
  }
  
  

  @media screen and (max-width:1024px){
    .btn {
     
      height: 7.2vw;
     margin-top: 1%;
      color: black;
      font-size: 2vw;
     font-weight: 300;
      padding-left: 7vw;
      /* transition: color 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86); */
    }
    .btn .btnText{
      padding-right: 5vw;
      padding-left: 3vw;
      width: 100%;
      line-height: 1;
      position: relative;
      z-index: 2;
    }
    .btn .btnCircle {
      clip-path: inset(10% calc(100% - 6.5vw) 10% 1vw round 1000px);
      position: absolute;
      inset: 0px;
      border-radius: 1000px;
      display: flex;
      align-items: center;
      z-index: 1;
      background-image: linear-gradient(to right, #9E7BDF, #ac26b0, #1e40af);
      background-size: 400%;
      color: white;
      transition: all 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
    .btn .btnIcon {
      width: 1.5vw;
      height: 1.5vw;
      overflow: hidden;
      margin-left: 3vw;
    }
  }
  @media screen and (max-width: 540px) {
    .btn {
     
      height: 14.2vw;
     margin-top: 1%;
      color: black;
      font-size: 4vw;
     font-weight: 300;
      padding-left: 12vw;
      /* transition: color 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86); */
    }
    .btn .btnText{
      padding-right: 7.5vw;
      padding-left: 3vw;
      width: 100%;
      line-height: 1;
      position: relative;
      z-index: 2;
    }
    .btn .btnCircle {
      clip-path: inset(10% calc(100% - 12.3vw) 10% 1.4vw round 1000px);
      position: absolute;
      inset: 0px;
      border-radius: 1000px;
      display: flex;
      align-items: center;
      z-index: 1;
      background-image: linear-gradient(to right, #9E7BDF, #ac26b0, #1e40af);
      background-size: 400%;
      color: white;
      transition: all 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
    .btn .btnIcon {
      width: 3.5vw;
      height: 3.5vw;
      overflow: hidden;
      margin-left: 5vw;
    }
  }